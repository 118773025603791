import {
  type Analytics,
  type Context,
  type Options as TrackOptions,
} from '@segment/analytics-next';
import { getCookie } from 'cookies-next';
import { getSessionId } from '@amplitude/analytics-browser';

class SegmentTracker {
  analytics: Analytics;
  context: Context;

  constructor(analytics: Analytics, context: Context) {
    this.analytics = analytics;
    this.context = context;

    // Add to window for legacy fallbacks
    if (typeof window !== 'undefined') {
      window.analytics = window.analytics || analytics;
      window.trackEventWithPayload = this.trackEventWithPayload;
      window.identifyWithPayload = this.identifyWithPayload;
    }
  }

  get anonymousId() {
    return this.analytics.user().anonymousId();
  }

  get sessionId() {
    return getSessionId();
  }

  /**
   * Taken from GTM
   *
   * Modified slightly
   * - Merged the `.page()` call with the `.track() visited_page` event
   * as these were being fired at the same time
   */
  
  async visitedPage(referrer?: string, eventData?: Record<string, any>) {
    await this.analytics.page();

    this.trackEventWithPayload('safetyculture_web.viewed_site', {
      has_logged_in: getCookie('sc_logged_in') ?? false,
      path: document.location.pathname,
      previous_path: referrer,
      referrer: document.referrer,
      url: document.location.href,
      ...eventData,
    });
  }

  /**
   * Taken from GTM
   */
  trackEventWithPayload(event: string, data: Record<string, any>) {
    // Avoiding Object.assign due to browser compatibility...
    const properties: Record<string, any> = {};
    const dataKeys = Object.keys(data);
    for (let i = 0; i < dataKeys.length; i++) {
      properties[dataKeys[i]] = data[dataKeys[i]];
    }

    const options: TrackOptions = {};
    const session_id = this.sessionId;

    if (session_id !== undefined) {
      options.integrations = {
        Amplitude: {
          session_id,
        },
      };
    }

    this.analytics.track(event, properties, options);
  }

  /**
   * Taken from GTM
   */
  identifyWithPayload(userId: string, identifyData: Record<string, any>) {
    this.analytics.identify(userId, identifyData);
  }

  /**
   * Taken from GTM
   */
  anonTracking() {
    const anonID = this.anonymousId;

    const pl_links = document.querySelectorAll<HTMLAnchorElement>(
      'a[href*="public-library.safetyculture.io"]',
    );

    if (pl_links) {
      pl_links.forEach((a) => {
        let linkParams = new URLSearchParams({
          amp_dev: anonID?.toString() ?? '',
        });

        if (window.location.search.includes('utm_')) {
          const utmParams = new URLSearchParams(window.location.search);

          linkParams = new URLSearchParams([
            ...Array.from(linkParams.entries()),
            ...Array.from(utmParams.entries()),
          ]);
        }

        a.href = `${a.href}?${linkParams.toString()}`;
      });
    }

    //hidden form elems for popups
    const hiddenId =
      document.querySelectorAll<HTMLInputElement>('input#amp_dev');

    if (hiddenId && anonID) {
      hiddenId.forEach((input) => {
        input.value = anonID;
      });
      document.addEventListener('om.Form.init', () => {
        hiddenId.forEach((input) => {
          input.value = anonID;
        });
      });
    }
  }

  /**
   * Taken from GTM
   */
  trackWistiaPlays() {
    if (typeof window !== 'undefined') {
      window._wq = window._wq || [];

      window._wq.push({
        id: '_all',
        onReady: (video: any) => {
          video.bind('play', () => {
            this.trackEventWithPayload('safetyculture_web.embedded_video', {
              action: 'started_video',
              video: video.name(),
              id: video.hashedId(),
            });
            return video.unbind;
          });

          video.bind('pause', () => {
            this.trackEventWithPayload('safetyculture_web.embedded_video', {
              action: 'paused_video',
              video: video.name(),
              id: video.hashedId(),
              watched: Math.round(video.percentWatched() * 100),
            });
            return video.unbind;
          });

          video.bind('end', () => {
            this.trackEventWithPayload('safetyculture_web.embedded_video', {
              action: 'finished_video',
              video: video.name(),
              id: video.hashedId(),
              watched: Math.round(video.percentWatched() * 100),
            });
            return video.unbind;
          });
        },
      });
    }
  }

  /**
   * Taken from GTM
   *
   * Modified slightly:
   * - Renamed function
   * - Re-ordered some lines
   * - Condensed some `.querySelector` calls
   */
  bindTrackingEvents() {
    //anon tracking for cross domain attribution
    this.anonTracking();

    //wistia videos
    this.trackWistiaPlays();

    //navigation
    const nav_links = document.querySelectorAll<HTMLAnchorElement>(
      'div[class^="Header_menu__"] > ul a',
    );
    const login = document.querySelector<HTMLAnchorElement>(
      'a[data-anchor="log-in-button"]',
    );
    const register = document.querySelector<HTMLAnchorElement>(
      'a[data-anchor="sign-up-button"]',
    );

    if (nav_links) {
      nav_links.forEach((item) => {
        item.addEventListener('click', (e: MouseEvent) => {
          this.trackEventWithPayload('safetyculture_web.navigation', {
            menuItemTitle: (e.currentTarget as typeof item).innerText,
            menuItemUrl: (e.currentTarget as typeof item).href,
            label: 'clicked_menu_link',
          });
        });
      });
    }
    if (login) {
      login.addEventListener('click', (e: MouseEvent) => {
        this.trackEventWithPayload('safetyculture_web.navigation', {
          menuItemTitle: (e.currentTarget as typeof login)?.innerText,
          menuItemUrl: (e.currentTarget as typeof login)?.href,
          label: 'clicked_login',
        });
      });
    }
    if (register) {
      register.addEventListener('click', (e: MouseEvent) => {
        this.trackEventWithPayload('safetyculture_web.navigation', {
          menuItemTitle: (e.currentTarget as typeof register).innerText,
          menuItemUrl: (e.currentTarget as typeof register).href,
          label: 'clicked_create_account',
        });
      });
    }

    //footer
    const footer_links = document.querySelectorAll<HTMLAnchorElement>(
      'div[class^="Footer_menu__"] a, div[class^="Footer_bar__"] a',
    );

    if (footer_links) {
      footer_links.forEach((item) => {
        item.addEventListener('click', (e: MouseEvent) => {
          this.trackEventWithPayload('safetyculture_web.footer', {
            menuItemTitle: (e.currentTarget as typeof item).innerText,
            menuItemUrl: (e.currentTarget as typeof item).href,
          });
        });
      });
    }

    // signup buttons
    const signup_btns = document.querySelectorAll<HTMLAnchorElement>(
      'main a[href^="https://app.safetyculture.com/sign-up"]',
    );

    if (signup_btns) {
      // event needs confirmation
      signup_btns.forEach((signup) => {
        const appCPTs = signup.closest('div[class^="AppTemplate_buttons__"]');
        const pricing = signup.closest('div[class^="Pricing_cta__"]');
        if (!pricing && !appCPTs) {
          signup.addEventListener('click', (e: MouseEvent) => {
            const url = new URL((e.target as typeof signup).href);
            const url_params = new URLSearchParams(url.search);
            const action_label = url_params.has('referrer', 'marketing-pl')
              ? 'template_download'
              : 'account_create';
            this.trackEventWithPayload('safetyculture_web.click_button', {
              label: action_label,
              path: window.location.pathname,
              target: url,
            });
          });
        }
      });
    }

    // login buttons
    const login_btns = document.querySelectorAll<HTMLAnchorElement>(
      'main a[href^="https://app.safetyculture.com/login"]',
    );

    if (login_btns) {
      login_btns.forEach((login) => {
        login.addEventListener('click', (e: MouseEvent) => {
          this.trackEventWithPayload('safetyculture_web.click_button', {
            path: window.location.pathname,
            label: 'login',
          });
        });
      });
    }

    // Book demo buttons - incl. multilingual versions
    const demo_btns = document.querySelectorAll<HTMLAnchorElement>(
      'a[href*="/book-demo/"], a[href*="/de/demo-buchen/"], a[href*="/es/reservar-demostracion/"], a[href*="/fr/reserver-une-demonstration/"], a[href*="/nl/demonstratie-boeken/"], a[href*="/pt-br/agendar-demonstracao/"]',
    );

    if (demo_btns) {
      demo_btns.forEach((demo) => {
        demo.addEventListener('click', (e: MouseEvent) => {
          this.trackEventWithPayload('safetyculture_web.click_button', {
            path: window.location.pathname,
            target: (e.currentTarget as typeof demo).href,
            label: 'book_demo',
          });
        });
      });
    }

    // Checklist tracking
    const checklists = document.querySelectorAll<HTMLUListElement>(
      'div[class^="Template_ctaButtonWrapper__"], ul[class^="Checklist_links__"]',
    );

    checklists.forEach((checklist) => {
      const pl = checklist.querySelector<HTMLAnchorElement>(
        'a[href^="https://public-library.safetyculture.io/"]',
      );
      const preview = checklist.querySelector<HTMLAnchorElement>(
        'a[href^="https://app.safetyculture.com/report"]',
      );
      const pdf = checklist.querySelector<HTMLAnchorElement>('a[href*=".pdf"]');

      if (pl) {
        pl.addEventListener('click', (e) => {
          this.trackEventWithPayload('safetyculture_web.click_button', {
            label: 'public_library_download',
            path: window.location.pathname,
            target: (e.target as typeof pl).href,
          });
        });
      }
      if (preview) {
        preview.addEventListener('click', (e) => {
          this.trackEventWithPayload('safetyculture_web.click_hyperlink', {
            label: 'checklist_previewed',
            path: window.location.pathname,
            target: (e.target as typeof preview).href,
          });
        });
      }
      if (pdf) {
        pdf.addEventListener('click', (e) => {
          this.trackEventWithPayload('safetyculture_web.click_hyperlink', {
            label: 'checklist_pdf_previewed',
            path: window.location.pathname,
            target: (e.target as typeof pdf).href,
          });
        });
      }
    });

    // Featured checklist previews
    const featured_checklist = document.querySelectorAll<HTMLDivElement>(
      'div[class^="FeaturedTemplate_ctaWrapper__"], div[class^="FeaturedTemplate_previewLinks__"]',
    );

    if (featured_checklist) {
      featured_checklist.forEach((checklist) => {
        const pdf =
          checklist.querySelector<HTMLAnchorElement>('a[href*=".pdf"]');
        const report = checklist.querySelector<HTMLAnchorElement>(
          'a[href^="https://app.safetyculture.com/report"]',
        );
        const preview = checklist.querySelector<HTMLAnchorElement>(
          'a[href^="https://public-library.safetyculture.io/"]',
        );
        if (preview) {
          preview.addEventListener('click', (e) => {
            this.trackEventWithPayload('safetyculture_web.click_button', {
              label: 'public_library_preview',
              path: window.location.pathname,
              target: (e.target as typeof preview).href,
            });
          });
        }
        if (report) {
          report.addEventListener('click', (e) => {
            this.trackEventWithPayload('safetyculture_web.click_hyperlink', {
              label: 'checklist_previewed',
              path: window.location.pathname,
              target: (e.target as typeof report).href,
            });
          });
        }
        if (pdf) {
          pdf.addEventListener('click', (e) => {
            this.trackEventWithPayload('safetyculture_web.click_hyperlink', {
              label: 'checklist_pdf_previewed',
              path: window.location.pathname,
              target: (e.target as typeof pdf).href,
            });
          });
        }
      });
    }

    // TOCs (Topics) - need to confirm for other pages
    const tocs = document.querySelectorAll<HTMLAnchorElement>('ul#toc a');

    if (tocs) {
      tocs.forEach((link) => {
        link.addEventListener('click', (e: MouseEvent) => {
          this.trackEventWithPayload('safetyculture_web.click_hyperlink', {
            label: (e.currentTarget as typeof link).innerText,
            path: window.location.pathname,
            target: (e.currentTarget as typeof link).href,
          });
        });
      });
    }

    // Apps CPT tracking
    const app_links = document.querySelectorAll<HTMLAnchorElement>(
      'div[class^="AppTemplate_buttons__"] a',
    );

    if (app_links) {
      app_links.forEach((link) => {
        link.addEventListener('click', (e: MouseEvent) => {
          const url = (e.currentTarget as typeof link).href;
          const label =
            url.indexOf('https://app.safetyculture.com/sign-up') != 1
              ? 'app_download'
              : (e.currentTarget as typeof link).innerText;
          this.trackEventWithPayload('safetyculture_web.click_button', {
            label: label,
            path: window.location.pathname,
            target: url,
          });
        });
      });
    }

    // FAQs
    const faqs_links = document.querySelectorAll<HTMLButtonElement>(
      'button[class^="Faq_button"]',
    );

    if (faqs_links) {
      faqs_links.forEach((faq) => {
        faq.addEventListener('click', (e: MouseEvent) => {
          this.trackEventWithPayload('safetyculture_web.faqs', {
            action: 'opened_faq',
            label: (e.currentTarget as typeof faq).innerText,
            path: window.location.pathname,
          });
        });
      });
    }

    // Cards - archive pages
    const cards = document.querySelectorAll('[class^="Card_root__"]');

    if (cards) {
      cards.forEach((card) => {
        const links = card.querySelectorAll<HTMLAnchorElement>('a');
        const label = card.querySelector<HTMLHeadingElement>('h3, h2');

        if (links) {
          links.forEach((link) => {
            link.addEventListener('click', (e: MouseEvent) => {
              this.trackEventWithPayload('safetyculture_web.click_card', {
                target: (e.currentTarget as typeof link).href,
                label: label?.innerText,
                path: window.location.pathname,
              });
            });
          });
        }
      });
    }

    // Pricing page
    const plans = document.querySelectorAll<HTMLAnchorElement>(
      'a[data-anchor="free_plan"], a[data-anchor="premium_plan"], a[data-anchor="enterprise_plan"], a[data-anchor="compare_seats"], a[data-anchor="compare_plans"]',
    );
    if (plans) {
      plans.forEach((plan) => {
        plan.addEventListener('click', (e: MouseEvent) => {
          this.trackEventWithPayload('safetyculture_web.pricing', {
            action:
              'clicked_' +
              (e.currentTarget as HTMLAnchorElement).dataset.anchor,
          });
        });
      });
    }

    // App store badge links
    const appstores = document.querySelectorAll<HTMLAnchorElement>(
      'a[data-anchor="app-store"], a[data-anchor="play-store"]',
    );

    if (appstores) {
      appstores.forEach((appstore) => {
        appstore.addEventListener('click', (e: MouseEvent) => {
          const label = (e.currentTarget as typeof appstore).dataset.anchor;
          const parent = (e.currentTarget as typeof appstore).closest(
            'section',
          );
          this.trackEventWithPayload('safetyculture_web.click_button', {
            action: 'download_app',
            label: label,
            path: window.location.pathname,
            object: parent?.id,
          });
        });
      });
    }

    // Download app banner
    const get_app = document.querySelector<HTMLAnchorElement>(
      'div[class^="Store_root__"] a[class^="Button_small__"]',
    );

    if (get_app) {
      get_app.addEventListener('click', (e: MouseEvent) => {
        this.trackEventWithPayload('safetyculture_web.click_button', {
          label: 'app_download',
          path: window.location.pathname,
          target: (e.currentTarget as typeof get_app).href,
        });
      });
    }

    // Notification banner
    const cta_btn = document.querySelector<HTMLAnchorElement>(
      'div[class^="NotificationBanner_buttons__"] a[class^="Button_small__"]',
    );

    if (cta_btn) {
      cta_btn.addEventListener('click', (e: MouseEvent) => {
        this.trackEventWithPayload('safetyculture_web.click_button', {
          label: 'notification_banner',
          path: window.location.pathname,
          target: (e.currentTarget as typeof cta_btn).href,
        });
      });
    }

    // Shortcode CTA
    const shortcodeCtaBtns = document.querySelectorAll<HTMLAnchorElement>('[class*="Cta_isShortcode__"] a');

    if(shortcodeCtaBtns) {
      shortcodeCtaBtns.forEach((ctaBtn) => {
        ctaBtn.addEventListener('click', (e: MouseEvent) => {

          const target = e.currentTarget as typeof ctaBtn;

          this.trackEventWithPayload('safetyculture_web.click_button', {
            label: 'seo_testimonial',
            path: window.location.pathname,
            target: target.href
          });
          
          // give a bit of time for the event to fire then navigate
          if(!target.classList.contains('clicked')) {
            e.preventDefault();

            target.classList.add('clicked');
            setTimeout(() => {
              target.click();
              target.classList.remove('clicked');
            }, 300);
          }
        });
      });
    }

    // AusOpen banner tracking
    const banner_cta = document.querySelector<HTMLAnchorElement>(
      'div[class^="TestimonialCta_testimonial__"] a[href*="/australian-open"]',
    );

    if (banner_cta) {
      banner_cta.addEventListener('click', (e: MouseEvent) => {
        this.trackEventWithPayload('safetyculture_web.click_button', {
          label: 'homepage_testimonial',
          path: window.location.pathname,
          target: (e.currentTarget as typeof banner_cta).href,
        });
      });
    }
  }
}

export default SegmentTracker;
