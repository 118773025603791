export const PAGE_LOADER: any = {
  threshold: 10,
  loading: {
    au: 'Loading...',
    de: 'Laden...',
    en: 'Loading...',
    es: 'Cargando...',
    fr: 'Chargement...',
    nl: 'Bezig met laden...',
    'pt-br': 'Carregando...',
    us: 'Loading...',
  },
};
