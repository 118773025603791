import { createContext, useContext } from 'react';

const DomainContext = createContext({} as string);

interface DomainProps {
  children: React.ReactNode;
}

const Domain = (props: DomainProps) => {
  const { children } = props;

  return (
    <DomainContext.Provider value={process.env.NEXT_PUBLIC_DOMAIN as string}>
      {children}
    </DomainContext.Provider>
  );
};

export default Domain;

export const useDomain = () => useContext(DomainContext);
