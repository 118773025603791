import { createContext, useContext, useState } from 'react';
import { getCookie } from 'cookies-next';

const NonceContext = createContext('');

interface NonceProps {
  children: React.ReactNode;
}

const Nonce = (props: NonceProps) => {
  const { children } = props;

  const nonce = getCookie('sc-cookie-nonce') || null;

  return (
    <NonceContext.Provider value={nonce as string}>
      {children}
    </NonceContext.Provider>
  );
};

export default Nonce;

export const useNonce = () => useContext(NonceContext);
