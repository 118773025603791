import { createContext, useContext, useState } from 'react';
import { getCookie } from 'cookies-next';

const NotificationBannerContext = createContext(true);

interface NonceProps {
  children: React.ReactNode;
}

const NotificationBannerCookie = (props: NonceProps) => {
  const { children } = props;

  const notificationBannerCookie: boolean =
    getCookie('sc-notification-banner') === undefined ? false : true;

  return (
    <NotificationBannerContext.Provider
      value={notificationBannerCookie as boolean}
    >
      {children}
    </NotificationBannerContext.Provider>
  );
};

export default NotificationBannerCookie;

export const useNotificationBanner = () =>
  useContext(NotificationBannerContext);
