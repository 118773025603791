import { createContext, useContext } from 'react';
import { getCookie } from 'cookies-next';

const CountryContext = createContext('US' as string);

interface CountryCodeProps {
  children: React.ReactNode;
}

const CountryCode = (props: CountryCodeProps) => {
  const { children } = props;

  const countryCookie = getCookie('sc-cookie-country');

  return (
    <CountryContext.Provider value={countryCookie as string}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryCode;

export const useCountry = () => useContext(CountryContext);
