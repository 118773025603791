import { createContext, useContext } from 'react';
import { useRouter } from 'next/router';

type Local = 'au' | 'de' | 'en' | 'es' | 'fr' | 'nl' | 'pt-br' | 'us';

const LocaleContext = createContext('us' as Local);

interface LocaleProps {
  children: React.ReactNode;
}

const Locale = (props: LocaleProps) => {
  const { children } = props;

  const { locale } = useRouter();

  return (
    <LocaleContext.Provider value={locale as Local}>
      {children}
    </LocaleContext.Provider>
  );
};

export default Locale;

export const useLocale = () => useContext(LocaleContext);
