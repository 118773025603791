import { createContext, useContext } from 'react';

const WebsiteContext = createContext({} as string);

interface WebsiteProps {
  children: React.ReactNode;
}

const Website = (props: WebsiteProps) => {
  const { children } = props;

  return (
    <WebsiteContext.Provider value={process.env.NEXT_PUBLIC_WEBSITE as string}>
      {children}
    </WebsiteContext.Provider>
  );
};

export default Website;

export const useWebsite = () => useContext(WebsiteContext);
