import {
  ApolloClient as APClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { sha256 } from 'crypto-hash';
import { print } from 'graphql/language/printer';

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
});

const persistedQueryLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
  sha256,
});

// Create a RetryLink to handle retries for failed persisted queries
const retryLink = new RetryLink({
  attempts: {
    // ... Max number of attempts for single request (includes initial request)
    max: 5,
    retryIf(error, operation) {
      return error?.statusCode === 500;
    },
  },
  delay: {
    initial: 300, // Initial delay in milliseconds
    max: Infinity, // No maximum delay
    jitter: true, // Add randomness to avoid spikes in load
  },
});

// const logQueryLink = new ApolloLink((operation, forward) => {
//   // console.log('[GraphQL Query]', print(operation.query));
//   return forward(operation);
// });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      console.error('[GraphQL Error]', error.message);
      console.error('[GraphQL Query]', print(operation.query));
      console.error('[GraphQL Query ERROR]', operation.operationName);
    });
  }

  if (networkError) {
    console.error('[Network Error]', networkError);
  }
});

const ApolloClient = new APClient({
  link: ApolloLink.from([
    // logQueryLink,
    errorLink,
    persistedQueryLink,
    retryLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
  },
});

export default ApolloClient;
