import '@/styles/index.scss';
import '@/styles/vendors/gradient.scss';
import '@/styles/vendors/highlight.scss';
import '@/styles/vendors/table.scss';
import '@/styles/vendors/wpforms.scss';
import CountryCode from '@/utilities/contexts/CountryContext';
import Algolia from '@/utilities/contexts/Algolia';

import Domain from '@/utilities/contexts/Domain';
import Locale from '@/utilities/contexts/Locale';
import Nonce from '@/utilities/contexts/Nonce';
import NotificationBannerCookie from '@/utilities/contexts/NotificationBanner';
import Website from '@/utilities/contexts/Website';
import SegmentProvider from '@/utilities/contexts/segment/SegmentProvider';
import ReactQueryProvider from '@/utilities/contexts/QueryProvider';
import cn from 'classnames';
import type { AppProps } from 'next/app';
import { Noto_Sans, Poppins } from 'next/font/google';
import PageLoader from '@/components/atoms/PageLoader';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from '@/utilities/helpers/Apollo';

const notosans = Noto_Sans({
  style: ['normal', 'italic'],
  subsets: ['latin'],
  variable: '--noto-sans',
  weight: ['400', '500', '700'],
});

const poppins = Poppins({
  style: ['normal'],
  subsets: ['latin'],
  variable: '--poppins',
  weight: ['700'],
});

const App = (props: AppProps) => {
  const { Component, pageProps } = props;

  return (
    <NotificationBannerCookie>
      <CountryCode>
        <Domain>
          <Website>
            <Locale>
              <Algolia>
                <SegmentProvider>
                  <ApolloProvider client={ApolloClient}>
                    <ReactQueryProvider>
                      <div className={cn(notosans.variable, poppins.variable)}>
                        <Nonce>
                          <PageLoader />
                          <Component {...pageProps} />
                        </Nonce>
                      </div>
                    </ReactQueryProvider>
                  </ApolloProvider>
                </SegmentProvider>
              </Algolia>
            </Locale>
          </Website>
        </Domain>
      </CountryCode>
    </NotificationBannerCookie>
  );
};

export default App;
