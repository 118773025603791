import styles from './PageLoader.module.scss';

import { PAGE_LOADER } from '@/utilities/constants/PageLoader';
import { useLocale } from '@/utilities/contexts/Locale';
import { useRouter } from 'next/router';
import React from 'react';
import Loader from '@/components/atoms/Loader';
import Typography from '@/components/atoms/Typography';

export default function PageLoader() {
  const [isLoading, setLoading] = React.useState(false);
  const locale = useLocale();
  const router = useRouter();

  React.useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    const start = () => {
      timer = setTimeout(() => setLoading(true), PAGE_LOADER.threshold);
    };

    const end = () => {
      if (timer) {
        clearTimeout(timer);
      }
      setLoading(false);
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);

    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);

      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [router.events]);

  if (!isLoading) return null;

  return (
    <div className={styles.root}>
      <Loader />
      <Typography xs={3} tag="div" family="noto-sans">
        {PAGE_LOADER.loading[locale]}
      </Typography>
    </div>
  );
}
